import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Wrapper, Grid, H, P } from '@farewill/ui'

import CreamBanner from 'components/CreamBanner'
import ExternalLink from 'components/ExternalLink'
import PressLogos from 'components/PressLogos'
import DefaultLayout from 'layouts/DefaultLayout'
import { normalizeArray } from 'lib/graphql/normalize'
import { PRESS_TEAM_EMAIL, PRESS_KIT_URL } from 'config'

import Card from './Card'

const Press = ({ data }) => {
  const pieces = normalizeArray(data.pieces)

  return (
    <DefaultLayout
      title="Press"
      description="Highlights of our press coverage and contact details for press inquiries"
    >
      <Wrapper container>
        <H size="XL" tag="h1" decorative>
          Press
        </H>
      </Wrapper>

      <CreamBanner
        imagePath="illustrations/letter-with-helper"
        imageAlt="Person with letter"
        imageWidth={300}
      >
        <H size="L" tag="h2">
          Press inquiries
        </H>
        <P>
          For press, awards, speaking, or sponsorship opportunities please get
          in touch with our press team by email on{' '}
          <ExternalLink href={`mailto:${PRESS_TEAM_EMAIL}`}>
            {PRESS_TEAM_EMAIL}
          </ExternalLink>
          .
        </P>
        <CreamBanner.Button tag={ExternalLink} href={PRESS_KIT_URL}>
          Download press kit
        </CreamBanner.Button>
      </CreamBanner>

      <Grid container>
        <Grid.Item>
          <H size="L" tag="h2" decorative>
            Coverage highlights
          </H>
        </Grid.Item>

        {pieces.map((piece) => (
          <Grid.Item key={piece.id} spanFromM={6} spanFromL={4}>
            <Card
              link={piece.link}
              title={piece.headline}
              description={piece.description && piece.description.description}
              publication={piece.publication}
              publishedAt={piece.publishedAt}
              image={piece.image}
            />
          </Grid.Item>
        ))}
      </Grid>

      <Wrapper container containerPaddingTop="M">
        <PressLogos />
      </Wrapper>
    </DefaultLayout>
  )
}

Press.propTypes = {
  data: PropTypes.shape({
    pieces: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            headline: PropTypes.string.isRequired,
            description: PropTypes.shape({
              description: PropTypes.string.isRequired,
            }),
            publication: PropTypes.string.isRequired,
            publishedAt: PropTypes.string.isRequired,
            image: PropTypes.shape({
              file: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }).isRequired,
              title: PropTypes.string.isRequired,
            }),
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query Press {
    pieces: allContentfulPressPiece(
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          link
          headline
          description {
            description
          }
          publication
          publishedAt
          image {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
`

export default Press
