import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H, P, Image, BulletSeparator } from '@farewill/ui'
import { COLOR, BORDER, GTR, BREAKPOINT } from '@farewill/ui/tokens'

import AspectRatioWrapper from 'components/AspectRatioWrapper'
import { formatPublishDates } from 'lib/time/formatting'

const IMAGE_ASPECT_RATIO = 16 / 9
const BORDER_WIDTH = 1

const StyledImageMask = styled(AspectRatioWrapper)`
  border: solid ${BORDER_WIDTH}px ${COLOR.GREY.LIGHT};
  border-radius: ${BORDER.RADIUS.S};
  background: ${COLOR.WHITE};
  overflow: hidden;
  margin-bottom: ${GTR.M};
`

const StyledMeta = styled(H)`
  margin-top: 0;
`

const StyledTitle = styled(H)``

const StyledPressCard = styled.a`
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;

    ${StyledTitle} {
      text-decoration: underline;
    }
  }
`

const PressCard = ({
  link,
  title,
  image,
  description,
  publication,
  publishedAt,
}) => (
  <StyledPressCard href={link} target="_blank">
    {image && image.file && (
      <StyledImageMask ratio={IMAGE_ASPECT_RATIO}>
        <Image
          alt={image.title}
          path={image.file.url}
          formatPath={({ width, path }) =>
            `${path}?w=${width}&h=${Math.round(
              width / IMAGE_ASPECT_RATIO + 3 * BORDER_WIDTH
            )}&fit=fill`
          }
          width={BREAKPOINT.XS}
          widthFromS={BREAKPOINT.S}
          widthFromM={660}
          widthFromL={540}
          stretch
        />
      </StyledImageMask>
    )}

    <StyledTitle size="S">{title}</StyledTitle>
    <P>{description}</P>
    {publishedAt && (
      <StyledMeta context>
        <BulletSeparator>
          <span>{publication}</span>
          <time dateTime={publishedAt}>{formatPublishDates(publishedAt)}</time>
        </BulletSeparator>
      </StyledMeta>
    )}
  </StyledPressCard>
)

PressCard.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  publication: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  publishedAt: PropTypes.string.isRequired,
}

export default PressCard
