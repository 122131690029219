import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Wrapper, Button } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

const StyledButton = styled(Button.Bordered)`
  ${screenMax.s`
    width: 100%;
  `}
`

const CreamBannerButton = ({ to, href, tag, children }) => (
  <Wrapper margin={['M', 0, 0]} marginFromM={['L', 0, 0]}>
    <StyledButton to={to} href={href} tag={tag} forward>
      {children}
    </StyledButton>
  </Wrapper>
)

CreamBannerButton.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node.isRequired,
}

CreamBannerButton.defaultProps = {
  to: undefined,
  href: undefined,
  tag: Link,
}

export default CreamBannerButton
