import React from 'react'
import styled from 'styled-components'
import { Wrapper, H } from '@farewill/ui'
import { GTR, COLOR, BORDER } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { alpha } from '@farewill/ui/helpers/colors'

import RetinaImage from 'components/RetinaImage'

const PressTitle = styled(H)`
  margin-bottom: ${GTR.S};

  ${screenMax.s`
    color: ${COLOR.BLACK};
    margin-bottom: ${GTR.M};
  `}
`

const Logo = styled.li`
  display: inline-block;
  margin: 0 ${GTR.M} ${GTR.M} 0;
  opacity: 0.4;

  ${screenMin.m`
    margin: 0 ${GTR.L} ${GTR.M} 0;
  `}
`

const LogosWrapper = styled.div`
  ${screenMax.s`
    position: relative;
    margin: 0 -${GTR.M};
    width: calc(100% + ${GTR.XL});

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      height: 100%;
      width: ${GTR.L};
      background: linear-gradient(to right, ${alpha(COLOR.WHITE, 0)}, ${
    COLOR.WHITE
  });
      top: 0;
      right: 0;
      pointer-events: none;
    }
  `};

  ${screenMin.m`
    padding: ${GTR.M} 0 0 ${GTR.L};
    background: ${COLOR.BACKGROUND.FOG};
    border: 1px solid ${COLOR.GREY.LIGHT};
    border-radius: ${BORDER.RADIUS.M};
  `}

  ${screenMin.xl`
    ${Logo}:nth-child(n+8) {
      display: none;
    }
  `}
`

const LogosScroller = styled.div`
  ${screenMax.s`
    overflow: auto;
    padding: 0 0 0 ${GTR.M};
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `};
`

const Logos = styled.ol`
  margin: 0;

  ${screenMax.s`
    width: 900px;
  `};
`

const pressLogos = [
  {
    title: 'BBC',
    fileName: 'bbc',
    width: 70,
  },
  {
    title: 'The Telegraph',
    fileName: 'telegraph',
    width: 129,
  },
  {
    title: 'The Guardian',
    fileName: 'guardian',
    width: 62,
  },
  {
    title: 'The Times',
    fileName: 'times',
    width: 174,
  },
  {
    title: 'Financial Adviser',
    fileName: 'ft',
    width: 137,
  },
  {
    title: 'Daily Express',
    fileName: 'express',
    width: 171,
  },
  {
    title: 'Daily Mail',
    fileName: 'mail',
    width: 127,
  },
  {
    title: 'MoneySavingExpert.com',
    fileName: 'mse',
    width: 270,
  },
  {
    title: 'Forbes',
    fileName: 'forbes',
    width: 82,
  },
  {
    title: 'Metro',
    fileName: 'metro',
    width: 82,
  },
]

const PressLogos = (): React.ReactElement => (
  <Wrapper centeredFromM margin={['L', 0, 0]} marginFromL={['M', 0, 0]}>
    <PressTitle context>As Featured In</PressTitle>
    <LogosWrapper>
      <LogosScroller>
        <Logos>
          {pressLogos.map((logo) => (
            <Logo key={logo.fileName}>
              <RetinaImage
                src={`/press/${logo.fileName}.png`}
                alt={logo.title}
                width={logo.width}
                height={20}
              />
            </Logo>
          ))}
        </Logos>
      </LogosScroller>
    </LogosWrapper>
  </Wrapper>
)

export default PressLogos
