import React from 'react'

interface RetinaImageProps {
  alt: string
  role?: string
  src: string
  src2x?: string
  suffix2x?: string
  width: string | number
  height: string | number
}

const RetinaImage = ({
  alt,
  role,
  src,
  src2x,
  suffix2x = '@2x',
  width,
  height,
}: RetinaImageProps): React.ReactElement => {
  const retinaSrc =
    src2x ||
    src.replace(
      /(.+)\.([a-z]+)/,
      (match, imagePath, ext) => `${imagePath}${suffix2x}.${ext}`
    ) // add suffix before extension

  return (
    <img
      src={src}
      srcSet={`${src} 1x, ${retinaSrc} 2x`}
      alt={alt}
      role={role || alt ? undefined : 'presentation'}
      width={width}
      height={height}
    />
  )
}

export default RetinaImage
