import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Grid, Image, BackgroundImageWrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import CreamBannerButton from './CreamBannerButton'

const StyledContent = styled(Grid.Item)`
  align-self: center;
`

const StyledImageWrapper = styled(Grid.Item).withConfig({
  shouldForwardProp: (prop) => !['showImageOnMobile'].includes(prop),
})`
  align-self: center;
  justify-self: center;

  ${screenMax.s`
    display: ${(props) => (props.showImageOnMobile ? 'block' : 'none')};
  `}
`

StyledImageWrapper.propTypes = {
  showImageOnMobile: PropTypes.bool,
}

const StyledGrid = styled(Grid)`
  background-color: ${COLOR.STATE.WARNING_10};

  ${screenMax.m`
    background-image: none;
  `}
`

const CreamBanner = ({
  children,
  imagePath,
  imageAlt,
  imageWidth,
  showImageOnMobile,
}) => (
  <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
    <StyledGrid
      bordered
      borderRadius="M"
      tag={BackgroundImageWrapper}
      imagePath="textures/composites/yellow-blob-smudge-corner"
      imageWidth={550}
      backgroundPosition="bottom right"
    >
      <StyledContent
        spanFromM={6}
        padding={showImageOnMobile ? ['M', 'M', 0] : 'M'}
        paddingFromM={['L', 0, 'L', 'L']}
      >
        {children}
      </StyledContent>

      <StyledImageWrapper
        spanFromM={6}
        spanFromL={5}
        startColumnFromL={8}
        padding={showImageOnMobile ? ['XS', 'S', 'XL', 'S'] : 'S'}
        paddingFromM="S"
        showImageOnMobile={showImageOnMobile}
        bumpSpecificity
      >
        <Image path={imagePath} alt={imageAlt} width={imageWidth} stretch />
      </StyledImageWrapper>
    </StyledGrid>
  </Wrapper>
)

CreamBanner.propTypes = {
  children: PropTypes.node.isRequired,
  imagePath: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageWidth: PropTypes.number,
  showImageOnMobile: PropTypes.bool,
}

CreamBanner.defaultProps = {
  imageWidth: 400,
  showImageOnMobile: false,
}

CreamBanner.Button = CreamBannerButton

export default CreamBanner
